<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
          :module_name="page.name"
          :record="record"
          @change="save"
          @archive="archive"
          :loading="loading"
          :model="page.model">
          <template v-slot:main_fields>
            <template v-for="field in usual_fields">
              <base-field
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-model="record.tags" :module_name="page.name"/>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="schemesessions_table" :page="page" :parent_module_id="record.id" relation_name="schemesessions" @create="createSchemesession" @edit="editSchemesession" @change="load"/>
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <base-create-dialog modal_name="create_note" ref="create_note" :page="notes_page" :files_required="true" :multiple_files="true" @refresh="load"/>
    <base-edit-dialog modal_name="edit_note" ref="edit_note" :page="notes_page" :files_required="true" :multiple_files="true" @refresh="load"/>
    <base-create-dialog modal_name="create_schemesession" ref="create_schemesession" :page="schemesessions_page" @refresh="load"/>
    <base-edit-dialog modal_name="edit_schemesession" ref="edit_schemesession" :page="schemesessions_page" @refresh="load"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseCreateDialog from "../../components/commonComponents/BaseCreateDialog.vue";
import BaseEditDialog from "../../components/commonComponents/BaseEditDialog.vue";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseEditDialog,
    BaseCreateDialog,
    BaseTableIncludable,
    BaseTabPanel,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: helpFunctions.pages.schemes,
      notes_page: helpFunctions.pages.notes,
      schemesessions_page: helpFunctions.pages.schemesessions,
      record: {},
      usual_fields: [],
      tags_required: false,
      loading: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      this.updateIncludableTables();
      await this.getFields();
    },
    updateIncludableTables() {
      this.$refs.schemesessions_table.records = this.record.scheme_sessions;
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.schemesessions_table.selected = [];
      this.$refs.notes_table.selected = [];
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    createSchemesession() {
      this.$refs.create_schemesession.load();
      this.$refs.create_schemesession.record.scheme_id = this.record.id;
    },
    editSchemesession(item) {
      this.$refs.edit_schemesession.load(item.id);
    },
    createNote() {
      this.$refs.create_note.load();
      this.$refs.create_note.record.parent_model = 'Scheme';
      this.$refs.create_note.record.parent_model_id = this.record.id;
    },
    editNote(item) {
      this.$refs.edit_note.load(item.id);
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.loading = false;
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>
